import { Transaction, TransactionSummary } from '../types'
import api from './api.service'

class TransactionService {
  static async list(params: {
    limit: number
    offset: number
    search: string
    status?: string
    startDate?: string
    endDate?: string
  }) {
    return (
      await api.get<{ total: number; transactions: Transaction[] }>(`/admin/transactions`, {
        params
      })
    ).data
  }

  static async getById(id: string) {
    return (await api.get<Transaction>(`/admin/transactions/${id}`)).data
  }

  static async summary(params: { startDate?: string; endDate?: string }) {
    return (
      await api.get<TransactionSummary>(`/admin/transactions-summary`, {
        params
      })
    ).data
  }
}

export default TransactionService
