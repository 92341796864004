import { useCallback, useEffect } from 'react'
import FocusTrap from 'focus-trap-react'

import { TModal } from './types'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon } from '../base/icon'

export const Modal: React.FC<TModal> = ({
  isOpen,
  children,
  ariaLabelledby,
  ariaDescribedby,
  withCloseButton,
  position = 'fixed',
  maxWidth = '400px',
  maxHeight = '90vh',
  minHeight = '200px',
  closeClickBg = true,
  onClose,
  className = '',
  modalBoxClassName = ''
}) => {
  const KEY_NAME_ESC = 'Escape'
  const KEY_EVENT_TYPE = 'keyup'

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ESC) onClose?.()
    },
    [onClose]
  )

  useEffect(() => {
    if (withCloseButton) document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

    return () => {
      if (withCloseButton) document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
    }
  }, [handleEscKey, withCloseButton])

  return (
    <AnimatePresence>
      {isOpen ? (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true
          }}
        >
          <div
            className={`inset-0 z-[999] flex ${position} items-center justify-center p-0 ${className}`}
          >
            <div
              onClick={closeClickBg ? onClose : undefined}
              className='absolute h-full w-full bg-[#000000bf]'
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              tabIndex={0}
              role='dialog'
              aria-labelledby={ariaLabelledby}
              aria-describedby={ariaDescribedby}
              transition={{ velocity: 100 }}
              style={{ maxWidth, minHeight, maxHeight }}
              className={`bg-background-primary relative flex h-full max-h-full w-full rounded-xl ${modalBoxClassName}`}
            >
              {withCloseButton && (
                <div className='absolute right-3.5 top-3.5 z-10'>
                  <Icon name='multiply' size='18' />
                </div>
              )}
              {children}
            </motion.div>
          </div>
        </FocusTrap>
      ) : null}
    </AnimatePresence>
  )
}
