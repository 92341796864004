import React from 'react'
import { Icon } from '../../../../../../components/base/icon'
import { Props } from './types'
import { addMonths, formatDate } from 'date-fns'
import theme from '../../../../../../styles/figma-tokens.json'

const TransactionsFilter: React.FC<Props> = ({ handleBackMonth, handleNextMonth, value }) => {
  const canNextMonth = addMonths(value, 1) > new Date()

  return (
    <div className='mb-4 mt-8 flex w-full justify-between'>
      <div className='flex items-center gap-2'>
        <span className='text-xs font-medium text-text-primary'>
          {formatDate(value, 'MMMM yyyy')}
        </span>
        <button>
          <Icon name='angle-down' size='18' />
        </button>
      </div>

      <div className='flex gap-2'>
        <button className='p-2' onClick={handleBackMonth}>
          <Icon name='angle-left' size='18' />
        </button>

        <button className='p-2' onClick={handleNextMonth} disabled={canNextMonth}>
          <Icon
            name='angle-right'
            size='18'
            {...(canNextMonth && { color: theme.global.colorReference.neutral[88].value })}
          />
        </button>
      </div>
    </div>
  )
}

export default TransactionsFilter
