/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import React from 'react'
import { Props } from './types'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../../../../components/base/icon'
import { formatDate } from 'date-fns'
import { TransactionTypes } from '../../../../utils'
import { DateFormatter } from './common'
import Button from '../../../../components/base/button'
import { masks } from '../../../../components/base/input/common'

export enum UserStatusParser {
  'active' = 'bg-background-success-secondary text-background-on-success-secondary',
  'pending' = 'bg-background-success-secondary text-background-on-success-secondary',
  'closed' = 'bg-background-success-secondary text-background-on-success-secondary',
  'suspended' = 'bg-background-success-secondary text-background-on-success-secondary',
  'inactive' = 'bg-background-critical-secondary text-background-on-critical-secondary'
}

const TransactionsTable: React.FC<Props> = ({ transactions, isPendingScreen }) => {
  const navigate = useNavigate()

  return (
    <motion.div
      className='mt-8 flex w-full flex-col'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='sticky top-0 z-10 flex w-full bg-white py-2'>
        <span className='flex-1 px-3 text-2xs font-medium text-neutral-32'>User</span>

        <span
          className={`${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} px-3 text-2xs font-medium text-neutral-32`}
        >
          Type
        </span>
        <span
          className={`${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} px-3 text-2xs font-medium text-neutral-32`}
        >
          {isPendingScreen ? 'Requested at' : 'Processed at'}
        </span>
        {!isPendingScreen && (
          <span
            className={`${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} px-3 text-2xs font-medium text-neutral-32`}
          >
            Approved by
          </span>
        )}

        <div className={`flex ${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} justify-end px-3`}>
          <span className=' text-2xs font-medium text-neutral-32'>Value</span>
        </div>

        {isPendingScreen && <div className='flex-[0.18]' />}
      </div>

      {Object.keys(transactions).map(key => (
        <div key={key} className='mt-4'>
          <span className='px-3 text-2xs font-regular text-text-tertiary'>
            {DateFormatter(new Date(key))}
          </span>

          {transactions[key].map((transaction, index) => {
            const className = TransactionTypes[transaction.type]?.className

            return (
              <motion.div
                key={transaction.uuid}
                className={`relative flex w-full items-center  ${transactions[key].length - 1 !== index && 'border-b-[1px] border-b-outline-default'} b py-3`}
              >
                <div className='flex flex-1 items-center px-3'>
                  <div
                    className={`${className} mr-3 flex h-10 w-10 items-center justify-center rounded-full `}
                  >
                    <Icon name={TransactionTypes[transaction.type]?.icon} size='18' />
                  </div>
                  <div className='flex flex-col'>
                    <span className='text-xs font-medium text-text-primary'>
                      {transaction.user.displayName}
                    </span>
                    <span className='text-2xs font-regular text-text-tertiary'>
                      {transaction.user.phone
                        ? masks['phone'](transaction.user.phone as string)
                        : 'Phone not defined'}
                    </span>
                  </div>
                </div>

                <div
                  className={`flex ${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} flex-col items-start justify-start px-3`}
                >
                  <span className='text-xs font-medium text-text-primary'>
                    {transaction.type.charAt(0).toUpperCase()}
                    {transaction.type.substring(1, 99)}
                  </span>
                  <span className='text-2xs font-regular text-text-tertiary'>
                    {transaction.serviceMethod.key.charAt(0).toUpperCase()}
                    {transaction.serviceMethod.key.substring(1, 99)}
                  </span>
                </div>

                <div
                  className={`flex ${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} flex-col items-start px-3`}
                >
                  <span className='text-xs font-medium text-text-primary'>
                    {formatDate(new Date(transaction.createdAt), 'hh:mm')} GMT
                  </span>
                </div>

                {!isPendingScreen && (
                  <div className='flex flex-1 flex-col items-start px-3'>
                    <span className='text-3xs font-regular text-text-primary'>
                      {transaction.admin?.name}
                    </span>
                  </div>
                )}

                <div
                  className={`flex ${isPendingScreen ? 'flex-[0.16]' : 'flex-1'} justify-end px-3`}
                >
                  <span className='text-xs font-medium text-text-primary'>
                    R${transaction.amount}
                  </span>
                </div>

                {isPendingScreen && (
                  <div className='flex flex-[0.18] items-end justify-end'>
                    <Button
                      id={`transaction-review`}
                      variant='secondary'
                      onClick={() => navigate(`${transaction.uuid}`)}
                      className='flex max-w-[94px] items-center justify-center gap-1 rounded-sm px-4 py-2'
                    >
                      <Icon name='info-circle' size='18' />
                      <span className='text-3xs font-medium text-text-primary'>Review</span>
                    </Button>
                  </div>
                )}
              </motion.div>
            )
          })}
        </div>
      ))}
    </motion.div>
  )
}

export default TransactionsTable
