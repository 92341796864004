import React from 'react'
import { TabProps } from '../types'
import TransactionsTable from '../../table'
import { Pagination } from '../../../../../components/pagination'
import PageLimitSelector from '../../../../../components/pagination/limit-selector'

const TransactionPending: React.FC<TabProps> = ({
  page,
  total,
  limit,
  transactions,
  handleChangePage,
  handleChangeLimit
}) => {
  return (
    <div className='flex h-full w-full flex-col'>
      <div className='h-full w-full overflow-auto'>
        <TransactionsTable transactions={transactions} isPendingScreen />
      </div>

      <div className='mt-6 flex w-full justify-between'>
        <Pagination
          currentPage={page + 1}
          onPageChange={page => handleChangePage(page - 1)}
          totalCount={Math.round(total / limit)}
        />

        <div className='flex items-center'>
          <span className='text-2xs font-regular text-text-secondary'>Rows per page:</span>
          <PageLimitSelector onChange={limit => handleChangeLimit(limit)} value={limit} />
          <span className='text-2xs font-regular text-text-secondary'>
            1-{limit >= total ? total : limit} of {total}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TransactionPending
