import { IconNameType } from '../../components/base/icon/Icon.data'
import { ROUTES } from '../../routes/path'

export type NavItem = {
  route?: string
  icon: IconNameType
  label: string
  hidden?: boolean
}

const NavItems: Array<NavItem[]> = [
  [
    {
      icon: 'chart',
      label: 'Dashboards',
      route: ROUTES.home.dashboards.base
    },
    {
      icon: 'users-alt',
      label: 'Transactions',
      route: ROUTES.home.transaction.base
    }
  ],
  [
    {
      icon: 'users-alt',
      label: 'Members',
      route: ROUTES.home.member.base
    },
    {
      icon: 'user-square',
      label: 'KYC',
      hidden: true
    },
    {
      icon: 'heart',
      label: 'Invites',
      hidden: true
    }
  ],
  [
    {
      icon: 'comment-dots',
      label: 'Messages',
      hidden: true
    },
    {
      icon: 'import',
      label: 'Loans',
      hidden: true
    },
    {
      icon: 'file-blank',
      label: 'Reports',
      hidden: true
    },
    {
      icon: 'dollar-sign-alt',
      label: 'Interest',
      hidden: true
    },
    {
      icon: 'wrench',
      label: 'Tools',
      hidden: true
    }
  ]
]

export default NavItems
