import React from 'react'
import { Icon } from '../../../../../components/base/icon'
import { Controller, useForm } from 'react-hook-form'
import { ModalProps } from './types'
import Button from '../../../../../components/base/button'
import { Input } from '../../../../../components/base/input'

const TransactionTypes = [
  {
    value: 'deposit',
    label: 'Deposit'
  },
  {
    value: 'withdrawal',
    label: 'Withdrawal'
  },
  {
    value: 'transfer',
    label: 'Transfer'
  }
]

const TransactionNotifications = [
  {
    value: 'push-notification',
    label: 'Push notification'
  },
  {
    value: 'sms',
    label: 'SMS'
  },
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'start-chat',
    label: 'Start chat'
  }
]

const TransactionForm: React.FC<ModalProps> = ({ handleClose, transaction }) => {
  const { control } = useForm({ defaultValues: transaction })

  return (
    <div className='flex w-full flex-col'>
      <div className='flex w-full items-center justify-between'>
        <span className='text-lg font-medium text-text-primary'>Deposit details</span>
        <button id='close-button' onClick={handleClose}>
          <Icon name='multiply' size='18' />
        </button>
      </div>

      <span className='mt-8 text-xs font-medium text-text-primary'>Type</span>
      <div className='mt-2 flex gap-2'>
        <Controller
          control={control}
          name='type'
          render={({ field }) => (
            <>
              {TransactionTypes.map(transaction => (
                <button
                  key={transaction.value}
                  id={`${transaction.value}-button`}
                  onClick={() => field.onChange(transaction.value)}
                  className={`rounded-sm border-[1px] px-3  py-1 text-2xs font-regular ${
                    field.value === transaction.value
                      ? 'border-outline-brand-primary bg-outline-brand-secondary text-background-on-brand-secondary'
                      : 'text-text-primary'
                  }`}
                >
                  {transaction.label}
                </button>
              ))}
            </>
          )}
        />
      </div>

      <div className='my-8 flex w-full flex-col gap-4'>
        <Controller
          name='amount'
          control={control}
          render={({ field }) => <Input {...field} id='amount' placeholder='Amount' />}
        />
      </div>

      <span className='text-xs font-medium text-text-primary'>Notifications</span>
      <div className='mt-2 flex gap-2'>
        <Controller
          control={control}
          name='admin.ambassador.club.name'
          render={({ field }) => (
            <>
              {TransactionNotifications.map(transaction => (
                <button
                  key={transaction.value}
                  id={`${transaction.value}-button`}
                  onClick={() => field.onChange(transaction.value)}
                  className={`rounded-sm border-[1px] px-3  py-1 text-2xs font-regular ${
                    field.value === transaction.value
                      ? 'border-outline-brand-primary bg-outline-brand-primary text-neutral-100'
                      : 'text-text-primary'
                  }`}
                >
                  {transaction.label}
                </button>
              ))}
            </>
          )}
        />
      </div>

      <div className='mt-4 '>
        <span className='rounded-sm bg-background-neutral-secondary p-3 text-2xs font-regular text-text-primary'>
          A push notification of Savings Collaborative app
        </span>
      </div>

      <Button variant='primary' id='approve' className='mt-8 w-full rounded-sm py-[14px]'>
        Continue
      </Button>
    </div>
  )
}

export default TransactionForm
