import React, { useCallback, useEffect, useState } from 'react'
import TransactionSummary from './summary'
import TransactionsFilter from './filter'
import { TabProps } from '../types'
import Button from '../../../../../components/base/button'
import { Icon } from '../../../../../components/base/icon'
import TransactionsTable from '../../table'
import { Pagination } from '../../../../../components/pagination'
import PageLimitSelector from '../../../../../components/pagination/limit-selector'
import { addMonths, format, subMonths } from 'date-fns'
import TransactionService from '../../../../../services/transaction.service'
import { TransactionSummary as TSummary } from '../../../../../types'

const TransactionApproved: React.FC<TabProps> = ({
  date,
  page,
  total,
  limit,
  transactions,
  handleChangeDate,
  handleChangePage,
  handleChangeLimit,
  handleCreateTransaction
}) => {
  const [summary, setSummary] = useState({ isLoading: true, data: {} as TSummary })

  const handleRefreshSummary = useCallback(() => {
    ;(() => {
      setSummary(previous => ({ ...previous, isLoading: true }))

      const year = date.getFullYear()
      const month = date.getMonth()
      const startDate = `${year}-${String(month + 1).padStart(2, '0')}-01`
      const endDate = new Date(year, month + 1, 0)
      const formattedEndDate = format(endDate, 'yyyy-MM-dd')

      TransactionService.summary({
        startDate,
        endDate: formattedEndDate
      }).then(summary =>
        setSummary({
          isLoading: false,
          data: summary
        })
      )
    })()

    setSummary(p => ({ ...p, isLoading: false }))
  }, [date])

  useEffect(() => {
    handleRefreshSummary()
  }, [handleRefreshSummary])

  return (
    <div className='flex h-full w-full flex-col'>
      <div className='flex h-full flex-col overflow-auto'>
        <span className='mt-8 text-2xs font-regular text-black'>Total balance</span>
        <div className='flex w-full items-center justify-between'>
          <span className='text-2xl font-medium text-black'>${summary.data.totalBalance}</span>

          <Button
            variant='secondary'
            id={'new-transaction'}
            className='flex items-center gap-1 rounded-sm text-2xs font-medium text-text-primary'
            onClick={handleCreateTransaction}
          >
            <Icon name='plus' size='18' />
            New transaction
          </Button>
        </div>

        <TransactionsFilter
          handleBackMonth={() => handleChangeDate(subMonths(date, 1))}
          handleNextMonth={() => handleChangeDate(addMonths(date, 1))}
          value={date}
        />

        <TransactionSummary {...summary.data} />

        <div className='h-full min-h-[800px] w-full'>
          <TransactionsTable transactions={transactions} />
        </div>
      </div>

      <div className='mt-6 flex w-full justify-between'>
        <Pagination
          currentPage={page + 1}
          onPageChange={page => handleChangePage(page - 1)}
          totalCount={Math.round(total / limit)}
        />

        <div className='flex items-center'>
          <span className='text-2xs font-regular text-text-secondary'>Rows per page:</span>
          <PageLimitSelector onChange={limit => handleChangeLimit(limit)} value={limit} />
          <span className='text-2xs font-regular text-text-secondary'>
            1-{limit >= total ? total : limit} of {total}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TransactionApproved
