import React from 'react'
import SummaryBox from './box'
import { TransactionSummary as TSummary } from '../../../../../../types'

const TransactionSummary: React.FC<TSummary> = ({ balance, deposits, withdrawals }) => {
  return (
    <div className='flex w-full justify-between rounded-md border-[1px] border-l-outline-default py-3 pt-4'>
      <SummaryBox icon='wallet-solid' label='Balance' value={balance} hideBorder />
      <SummaryBox icon='dollar-sign-alt' label='Deposits' value={deposits} />
      <SummaryBox icon='upload' label='Withdrawals' value={withdrawals} />
    </div>
  )
}

export default TransactionSummary
