import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../../../components/base/button'
import { Input } from '../../../components/base/input'
import theme from '../../../styles/figma-tokens.json'
import { Transaction } from '../../../types'
import { Icon } from '../../../components/base/icon'
import { IconNameType } from '../../../components/base/icon/Icon.data'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDebounce } from '@uidotdev/usehooks'
import TransactionService from '../../../services/transaction.service'
import Tab from '../../../components/tab'
import { TabItem } from '../../../components/tab/types'
import * as TabComponents from './tabs'
import { AnimatePresence } from 'framer-motion'
import DefaultLoader from '../../../components/base/loaders/default'
import { groupByCreatedAt } from '../../../utils'
import { Modal } from '../../../components/modals'
import TransactionDetail from './modals/detail'
import TransactionForm from './modals/form'
import { format } from 'date-fns'

const defaultTableParams = {
  filter: '',
  page: '0',
  limit: '50',
  t: 'pending',
  date: ''
}

const Tabs: TabItem<keyof typeof TabComponents>[] = [
  {
    label: 'Pending',
    key: 'pending',
    counter: 5
  },
  {
    label: 'Approved',
    key: 'approved'
  }
]

const HomeTransactions = () => {
  const [transactions, setTransactions] = useState({
    isLoading: true,
    data: [] as Transaction[],
    total: 0
  })
  const [searchParams, setSearchParams] = useSearchParams(defaultTableParams)
  const tab = Tabs.find(tab => tab.key === searchParams.get('t')) || Tabs[0]
  const TabComponent = TabComponents[tab.key]

  const date = useMemo(
    () => (searchParams.get('date') ? new Date(searchParams.get('date') as string) : new Date()),
    [searchParams]
  )
  const filter = searchParams.get('filter') || defaultTableParams.filter
  const page = searchParams.get('page') || defaultTableParams.page
  const limit = searchParams.get('limit') || defaultTableParams.limit
  const filterDebounced = useDebounce(filter, 500)
  const [transaction, setTransaction] = useState<Transaction>()
  const navigate = useNavigate()
  const { id } = useParams()

  const handleRefreshTransactions = useCallback(() => {
    ;(() => {
      setTransactions(previous => ({ ...previous, isLoading: true }))

      const year = date.getFullYear()
      const month = date.getMonth()
      const startDate = `${year}-${String(month + 1).padStart(2, '0')}-01`
      const endDate = new Date(year, month + 1, 0)
      const formattedEndDate = format(endDate, 'yyyy-MM-dd')

      TransactionService.list({
        limit: parseInt(limit),
        offset: parseInt(limit) * parseInt(page),
        search: filterDebounced,

        ...(tab.key === 'pending'
          ? { status: 'pending' }
          : { startDate, endDate: formattedEndDate })
      })
        .then(transaction =>
          setTransactions({
            isLoading: false,
            data: transaction.transactions,
            total: transaction.total
          })
        )
        .catch(err => console.log(err))
    })()

    setTransactions(p => ({ ...p, isLoading: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filterDebounced, tab, date])

  useEffect(() => {
    handleRefreshTransactions()
  }, [handleRefreshTransactions])

  const tableButtons = [
    /*     {
      label: 'Filters',
      icon: 'sliders-v',
      onClick: () => {}
    },
    {
      label: 'Export',
      icon: 'upload',
      onClick: () => {}
    }, */
    {
      label: 'Refresh',
      icon: 'redo',
      onClick: handleRefreshTransactions
    }
  ]

  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div className='flex h-full w-full max-w-[1130px] flex-col'>
        <div className='mb-4 flex items-center justify-between'>
          <span className='text-xl font-bold text-black'>Transactions</span>
        </div>

        <Tab
          tabs={Tabs}
          current={tab}
          handleChange={e =>
            setSearchParams({ filter, limit, t: e.key, ...(date && { date: date.toUTCString() }) })
          }
        />

        <div className='mt-4 flex w-full gap-4'>
          <Input
            id='search-member'
            placeholder='Search'
            icon='search'
            iconColor={theme.global.colorReference.neutral['40'].value}
            variant='search'
            value={filter}
            onChange={e =>
              setSearchParams({
                page: defaultTableParams.page,
                limit,
                filter: e.target.value,
                t: tab.key,
                ...(date && { date: date.toUTCString() })
              })
            }
            layout='small'
          />

          {tableButtons.map(button => (
            <Button
              className='flex items-center gap-1 rounded-sm'
              key={button.label.toLowerCase()}
              id={button.label.toLowerCase()}
              variant='secondary'
              onClick={button.onClick}
            >
              <Icon name={button.icon as IconNameType} size='18' />
              <span className='text-2xs font-bold text-black'>{button.label}</span>
            </Button>
          ))}
        </div>

        <div className='h-full w-full overflow-auto'>
          <AnimatePresence>
            {transactions.isLoading ? (
              <div className='flex h-full items-center justify-center'>
                <DefaultLoader />
              </div>
            ) : (
              <TabComponent
                date={date}
                total={transactions.total}
                limit={parseInt(limit)}
                page={parseInt(page)}
                transactions={groupByCreatedAt(transactions.data)}
                handleChangeDate={date =>
                  setSearchParams({ filter, page, limit, t: tab.key, date: date.toUTCString() })
                }
                handleCreateTransaction={() => setTransaction({} as Transaction)}
                handleChangePage={page =>
                  setSearchParams({
                    filter,
                    page: (page - 1).toString(),
                    limit,
                    t: tab.key,
                    ...(date && { date: date.toUTCString() })
                  })
                }
                handleChangeLimit={limit =>
                  setSearchParams({
                    filter,
                    page,
                    limit: limit.toString(),
                    t: tab.key,
                    ...(date && { date: date.toUTCString() })
                  })
                }
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      {id && (
        <Modal
          isOpen={!!id}
          onClose={() => navigate(-1)}
          ariaLabelledby='Transaction detail modal'
          modalBoxClassName='p-4'
          className='!items-start !justify-end'
          maxHeight='100vh'
          maxWidth='468px'
        >
          <TransactionDetail handleClose={() => navigate(-1)} />
        </Modal>
      )}

      <Modal
        maxWidth='480px'
        isOpen={!!transaction}
        onClose={() => setTransaction(undefined)}
        ariaLabelledby='Transaction form modal'
        className='!items-center !justify-center'
        modalBoxClassName='p-6 bg-white !h-auto w-[480px]'
      >
        <TransactionForm
          handleClose={() => setTransaction(undefined)}
          transaction={transaction as Transaction}
        />
      </Modal>
    </div>
  )
}

export default HomeTransactions
