import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../components/base/button'
import { LoginFormValues } from './types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import AuthService from '../../services/auth.service'
import DefaultLoader from '../../components/base/loaders/default'
import { Input } from '../../components/base/input'
import { useToastContext } from '../../contexts/ToastContext'

const initialValues: LoginFormValues = {
  username: '',
  password: ''
}

const validationSchema = Yup.object({
  username: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required')
})

type Props = {
  handleSubmit: (props: LoginFormValues) => void
}

const LoginForm: React.FC<Props> = ({ handleSubmit }) => {
  const navigate = useNavigate()
  const { toast } = useToastContext()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: LoginFormValues) => {
      try {
        await AuthService.login(values)
        handleSubmit(values)
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toast.error((err as any).toString(), { comeFrom: 'bottom-left' })
      }
    }
  })

  return (
    <div className='mt-16 flex w-[454px] flex-col'>
      <img
        alt={'Savings collaborative full logo'}
        src='/icons/logos/full.svg'
        className='h-10 w-44 self-start'
      />
      <div>
        <span className='mt-8 text-xl font-medium text-neutral-8'>Log in</span>
      </div>
      <span className='mt-2 text-xs font-regular text-neutral-32'>
        Welcome back. Please enter your details.
      </span>

      {formik.isSubmitting ? (
        <DefaultLoader className='mt-8 w-full self-center' />
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} className='mt-8 flex w-full flex-col gap-4'>
            <Input
              className='text-black'
              placeholder='Email'
              id='username'
              name='username'
              type='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              maxLength={100}
              errorMessage={
                formik.touched.username && formik.errors.username
                  ? formik.errors.username
                  : undefined
              }
            />

            <Input
              className='text-black'
              placeholder='Password'
              id='password'
              name='password'
              type='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              maxLength={100}
              errorMessage={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : undefined
              }
            />

            <Button id='login' variant='primary' className='mt-4 rounded-sm py-4' type='submit'>
              Continue
            </Button>

            <button
              id='forgot-password-button'
              className='mt-11 w-fit text-2xs font-medium text-brand-40 underline decoration-solid'
              onClick={() => navigate(ROUTES.forgotPassword)}
            >
              FORGOT MY PASSWORD
            </button>
          </form>
        </>
      )}
    </div>
  )
}

export default LoginForm
