import { router } from './routes'
import './styles/global.css'
import './styles/index.css'
import { RouterProvider } from 'react-router-dom'
import { AuthProvider } from './contexts/auth'
import { ToastProvider } from './contexts/ToastContext'

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <RouterProvider router={router} />
      </ToastProvider>
    </AuthProvider>
  )
}

export default App
