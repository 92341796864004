import React from 'react'
import { TabProps } from './types'
import { useNavigate } from 'react-router-dom'

const TabWrapper: React.FC<TabProps & { children: React.ReactNode }> = props => {
  const { user, children } = props
  const navigate = useNavigate()

  return (
    <div className='flex h-full w-full flex-col items-start'>
      <div className='mt-4 flex'>
        <button
          className='text-2xs font-regular text-text-primary underline'
          onClick={() => navigate(-1)}
        >
          Members{' '}
        </button>
        <span className='ml-1 text-2xs font-regular text-text-primary'>/ {user?.fullname}</span>
      </div>

      {children}
    </div>
  )
}

export default TabWrapper
