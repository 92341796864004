import React from 'react'
import { TPosition, TToast } from './types'
import { styleVariant, styleClasses, position } from '../../utils/toast'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon } from '../base/icon'

export const Toast: React.FC<TToast> = ({
  content,
  open,
  comeFrom = 'top-center',
  variant = 'positive',
  className = '',
  handleClose
}) => {
  const from = comeFrom.split('-')[0] as 'top' | 'bottom'
  const positionToast = comeFrom.split('-')[1] as TPosition

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={styleClasses[from].initial}
          animate={styleClasses[from].animate}
          exit={styleClasses[from].exit}
          className={'fixed z-[999] ml-8 w-[285px] sm:w-[350px] md:ml-0'}
          style={{
            ...position[from],
            ...position[positionToast]
          }}
        >
          <div
            role='alert'
            className={`flex w-full max-w-full ${styleVariant[variant].style} place-content-between rounded-lg  p-4 ${className}`}
          >
            <div className='flex gap-2'>
              <Icon
                name={styleVariant[variant].iconName}
                size='24px'
                color={variant !== 'warning' ? 'white' : 'black'}
              />
              {content}
            </div>
            <button
              onClick={handleClose}
              aria-label='Fechar toast'
              className={open ? 'block' : 'hidden'}
            >
              <Icon name='times' size='24px' color={variant !== 'warning' ? 'white' : 'black'} />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
