import { format, isToday, isYesterday } from 'date-fns'

export function DateFormatter(inputDate: Date) {
  if (isToday(inputDate)) {
    return 'Today'
  } else if (isYesterday(inputDate)) {
    return 'Yesterday'
  } else {
    return format(inputDate, 'MMM dd')
  }
}
