import { IconNameType } from '../components/base/icon/Icon.data'
import { Transaction } from '../types'

export const TransactionTypes: {
  [key in Transaction['type']]: {
    className: string
    icon: IconNameType
  }
} = {
  deposit: {
    className: 'bg-green-96',
    icon: 'dollar-sign-alt'
  },
  withdraw: {
    className: 'bg-brown-96',
    icon: 'upload'
  }
}

export function groupByCreatedAt(data: Transaction[]): Record<string, Transaction[]> {
  return data.reduce(
    (acc, item) => {
      const createdAtDate = item.createdAt.split('T')[0] // Pega apenas a data
      if (!acc[createdAtDate]) {
        acc[createdAtDate] = []
      }
      acc[createdAtDate].push(item)
      return acc
    },
    {} as Record<string, Transaction[]>
  )
}
