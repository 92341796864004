import React from 'react'
import { Props } from './types'
import { Icon } from '../../../../../../../components/base/icon'

const SummaryBox: React.FC<Props> = ({ icon, label, value, hideBorder }) => {
  return (
    <div
      className={`flex h-full w-64 gap-3 ${!hideBorder && 'border-l-[1px] border-l-outline-default'} px-3`}
    >
      <div className='flex h-12 w-12 items-center justify-center rounded-full bg-background-neutral-secondary'>
        <Icon name={icon} size='18' color='#523D8F' />
      </div>

      <div className='flex flex-col items-start gap-1'>
        <span className='text-3xs font-regular text-text-secondary'>{label}</span>
        <span className='text-xs font-medium text-text-primary'>${value}</span>
      </div>
    </div>
  )
}

export default SummaryBox
