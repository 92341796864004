import React, { useEffect, useState, useTransition } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '../../../../../components/base/icon'
import theme from '../../../../../styles/figma-tokens.json'
import Button from '../../../../../components/base/button'
import { Props } from '../types'
import { Transaction } from '../../../../../types'
import { useToastContext } from '../../../../../contexts/ToastContext'
import TransactionService from '../../../../../services/transaction.service'
import DefaultLoader from '../../../../../components/base/loaders/default'

const TransactionDetail: React.FC<Props> = ({ handleClose }) => {
  const [transaction, setTransaction] = useState<Transaction>()
  const [loading, startTransition] = useTransition()
  const { toast } = useToastContext()

  const navigate = useNavigate()
  const { id } = useParams()
  if (!id) navigate(-1)

  useEffect(() => {
    try {
      startTransition(async () => {
        const res = await TransactionService.getById(id as string)
        setTransaction(res)
      })
    } catch (_) {
      toast.error('Error when try to get this transaction.')
    }
  }, [toast, id])

  return (
    <div className='flex h-full w-[468px] flex-col items-start justify-between rounded-[20px] bg-white'>
      {loading ? (
        <div className='flex h-full w-full items-center justify-center'>
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className='flex w-full flex-col p-9 pb-0'>
            <div className='flex w-full items-center justify-between'>
              <span className='text-lg font-medium text-text-primary'>Deposit details</span>
              <button id='close-button' onClick={handleClose}>
                <Icon name='multiply' size='18' />
              </button>
            </div>

            <span className='mt-8 text-xl font-medium text-text-primary'>
              {transaction?.amount}
            </span>

            <div className='mt-8 flex flex-col items-start'>
              <span className='text-2xs font-regular text-text-secondary'>Value</span>
              <span className='text-2xl font-medium text-text-primary'>${transaction?.amount}</span>
            </div>

            <div className='flex w-full flex-col'>
              <div className='flex items-center gap-4 border-b-[1px] border-b-outline-default py-4'>
                <div className='h-5 w-5 rounded-full bg-slate-200' />

                <div className='flex flex-col items-start gap-1'>
                  <span className='text-xs font-regular text-text-primary'>Account</span>
                  <span className='text-2xs font-regular text-text-secondary'>Antonio Barreto</span>
                </div>
              </div>
            </div>

            <div className='flex w-full flex-col'>
              <div className='flex items-center gap-4 border-b-[1px] border-b-outline-default py-4'>
                <Icon
                  name='calendar'
                  size='18'
                  color={theme.global.colorReference.neutral[40].value}
                />

                <div className='flex flex-col items-start gap-1'>
                  <span className='text-xs font-regular text-text-primary'>Date and time</span>
                  <span className='text-2xs font-regular text-text-secondary'>
                    June 29, 2022 at 11:00
                  </span>
                </div>
              </div>
            </div>

            <div className='flex w-full flex-col'>
              <div className='flex items-center gap-4 py-4'>
                <Icon
                  name='info-circle'
                  size='18'
                  color={theme.global.colorReference.neutral[40].value}
                />

                <div className='flex flex-col items-start gap-1'>
                  <span className='text-xs font-regular text-text-primary'>Status</span>
                  <span className='text-2xs font-regular text-text-secondary'>Pending</span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex w-full justify-end gap-4 border-t-[1px] border-t-outline-default p-4'>
            <Button variant='primary_negative' id='approve' className='w-full rounded-sm py-[14px]'>
              Do not approve
            </Button>
            <Button variant='primary' id='approve' className='w-full rounded-sm py-[14px]'>
              Approve
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default TransactionDetail
