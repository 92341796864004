import React, { useCallback, useEffect, useState } from 'react'
import { Input } from '../../../components/base/input'
import theme from '../../../styles/figma-tokens.json'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Dashboard } from '../../../types'
import { useDebounce } from '@uidotdev/usehooks'
import DashboardService from '../../../services/dashboard.service'
import { AnimatePresence, motion } from 'framer-motion'
import DefaultLoader from '../../../components/base/loaders/default'
import DashboardCard from './card'
import { ROUTES } from '../../../routes/path'

const defaultTableParams = {
  filter: ''
}

const HomeDashboards = () => {
  const [dashboards, setDashboards] = useState({ isLoading: true, data: [] as Dashboard[] })
  const [searchParams, setSearchParams] = useSearchParams(defaultTableParams)
  const filter = searchParams.get('filter') || defaultTableParams.filter
  const filterDebounced = useDebounce(filter, 500)
  const navigate = useNavigate()

  const handleRefreshDashboards = useCallback(() => {
    setDashboards(previous => ({ ...previous, isLoading: true }))

    DashboardService.list({
      search: filterDebounced
    }).then(dashboards => setDashboards({ isLoading: false, data: dashboards }))
  }, [filterDebounced])

  const handleLike = async (dashboard: Dashboard) => {
    try {
      setDashboards(previous => ({
        ...previous,
        data: previous.data.map(dash =>
          dash.id === dashboard.id ? { ...dash, isLiked: !dash.isLiked } : dash
        )
      }))

      if (dashboard.isLiked) {
        return await DashboardService.dislikeDashboard(dashboard.id)
      }

      return await DashboardService.likeDashboard(dashboard.id)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleRefreshDashboards()
  }, [handleRefreshDashboards])

  return (
    <div className='flex w-full items-center justify-center'>
      <div className='flex h-full w-full max-w-[848px] flex-col'>
        <div className='flex items-center justify-between'>
          <span className='text-xl font-bold text-black'>Dashboards</span>
        </div>

        <Input
          id='search-member'
          placeholder='Search'
          icon='search'
          iconColor={theme.global.colorReference.neutral['40'].value}
          variant='search'
          value={filter}
          className='mt-8'
          onChange={e => setSearchParams({ filter: e.target.value })}
          layout='small'
        />

        <AnimatePresence>
          {dashboards.isLoading ? (
            <div className='flex h-full items-center justify-center'>
              <DefaultLoader />
            </div>
          ) : (
            <motion.div
              className='mt-8 grid grid-cols-2 gap-9  pb-8'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {dashboards.data.map(dashboard => (
                <DashboardCard
                  key={dashboard.id}
                  dashboard={dashboard}
                  handleLike={() => handleLike(dashboard)}
                  handleOpen={() =>
                    navigate(`${ROUTES.home.dashboards.base}/${dashboard.supersetId}`, {
                      relative: 'path'
                    })
                  }
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default HomeDashboards
