import { useState } from 'react'
import { Icon } from '../icon'
import { Props } from './types'

export default function PinInput({ length, id, errorMessage, ...inputProps }: Props) {
  const [isFocused, setIsFocused] = useState(false)
  const val = inputProps.value ? inputProps.value.toString() : ''
  const len = Array.from({ length }, (_, i) => i + 1)

  console.log(errorMessage)

  return (
    <div className='flex flex-col items-start'>
      <div className='relative flex items-center justify-start'>
        <div className='flex justify-center gap-4 self-center'>
          {len.map((_, index) => (
            <div
              key={index}
              className={`flex h-[50px] w-[50px] items-center rounded-sm border-[1px] ${isFocused && index === val.toString().length && 'border-brand-64'}`}
            >
              <span className='w-full text-center font-brand text-sm text-neutral-8'>
                {val.substring(index, index + 1)}
              </span>
            </div>
          ))}
        </div>

        <input
          {...inputProps}
          maxLength={length}
          id={`${id}-pininput`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className='absolute left-0 top-0 h-[56px] w-full opacity-0'
        />
      </div>

      {errorMessage && (
        <span
          id={`${id}-error-message`}
          className='label-md mt-1 flex items-start gap-1 text-black'
        >
          <Icon name='exclamation-circle-solid' size='16px' className='mt-0.5' color='#DD1940' />
          {errorMessage}
        </span>
      )}
    </div>
  )
}
